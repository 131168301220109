import * as React from 'react'

import * as common from '@rushplay/common'

import { Heading } from '../heading'

export function ShutDownPage() {
  return (
    <common.Flex
      flexGrow="1"
      flexShrink="1"
      flexBasis="0"
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <common.Box
        height={['40px', '80px']}
        mb="40px"
        mt="80px"
        mx="auto"
        as="img"
        alt="Amuletobet"
        src="/images/amuletobet-logo-long.svg"
      />
      <common.Box fontSize={['20px', '24px']} textAlign="center" width="100%">
        <common.Box pb="16px">
          <Heading level={2} fontSize={['24px', '32px']}>
            Amuletobet is no longer operational.
          </Heading>
        </common.Box>
        For more information please contact:&nbsp;
        <common.Box
          as="a"
          href="mailto:support@amuletobet.com"
          textAlign="center"
          style={{ textDecoration: 'underline' }}
        >
          support@amuletobet.com
        </common.Box>
      </common.Box>
    </common.Flex>
  )
}

// for @loadable/components
export default ShutDownPage
